@switch (eventId) {
  @case ('ams.instruction.rsn.completed.client.sender') {
    Your account has been debited per your RSN Transfer instruction; your transfer is complete.
  }
  @case ('ams.instruction.rsn.completed.client.receiver') {
    You've received funds in your account from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} for {{ requestData.currency }} {{ requestData.amount }} transferred on
    {{ requestData.dateUpdated }}.
  }
  @case ('ams.instruction.rsn.initiated.client') {
    RSN payment created for {{ requestData.currency }} {{ requestData.amount }} from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} to {{ requestData.toPackageName }} -
    {{ requestData.toServiceAccountNumber }} (Ref: {{ requestData.referenceNumber }})
  }
  @case ('ams.instruction.rsn.pending.client') {
    RSN instruction for {{ requestData.currency }} {{ requestData.amount }} from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} to {{ requestData.toPackageName }} -
    {{ requestData.toServiceAccountNumber }} is in progress (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.rsn.completed.client') {
    RSN instruction for {{ requestData.currency }} {{ requestData.amount }} from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} to {{ requestData.toPackageName }} -
    {{ requestData.toServiceAccountNumber }} is complete (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.rsn.cancelled.client') {
    RSN instruction for {{ requestData.currency }} {{ requestData.amount }} from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} to {{ requestData.toPackageName }} -
    {{ requestData.toServiceAccountNumber }} has been cancelled (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.rsn.completed.client') {
    RSN instruction for {{ requestData.currency }} {{ requestData.amount }} from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} to {{ requestData.toPackageName }} -
    {{ requestData.toServiceAccountNumber }} is complete (Ref: {{ requestData.referenceNumber }}).
  }
}
