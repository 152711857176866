import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, HostListener, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { ApplicationSettingsService } from '@app/core/services';
import { LogoutService } from '@app/core/services/logout.service';
import { LayoutModuleSettings } from '@app/layout/layout-module.settings';
import { TourService } from '@app/tour/tour.service';
import { delay, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-nps-survey-popup',
  imports: [FdtIconModule, FdtButtonModule, NgStyle],
  templateUrl: './nps-survey-popup.component.html',
  styleUrl: './nps-survey-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpsSurveyPopupComponent {
  isTemporaryHidden = signal(true);
  isVisibleSignal = signal<boolean>(true);
  layoutSettings = this.appSettingsService.getSettings<LayoutModuleSettings>('layout');

  constructor(
    private appSettingsService: ApplicationSettingsService,
    private logoutService: LogoutService,
    private elementRef: ElementRef,
    private tourService: TourService,
    private destroyRef: DestroyRef
  ) {
    this.layoutSettings = this.appSettingsService.getSettings<LayoutModuleSettings>('layout');
    const initialyVisible = this.isInitialyVisible();
    this.isVisibleSignal.set(initialyVisible);

    if (!initialyVisible) {
      // if initialy not visible, we need to increase the session skiped count
      // in order to potentialy show the popup on the next session
      this.increaseSessionSkipedIfNeeded();
    }

    this.tourService.hasActiveTour$
      .pipe(
        switchMap((hasActiveTour) => {
          if (hasActiveTour) {
            // Proceed immediately if it's true, we want to hide the popup temporary ASAP;
            return of(true);
          }

          // Delay 5000ms if false since second tour can be started immediately after the first one ends
          return of(false).pipe(delay(5000));
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((hasActiveTour) => {
        this.isTemporaryHidden.set(hasActiveTour);
      });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const isOutsideClick = !this.elementRef.nativeElement.contains(event.target);

    if (isOutsideClick && this.isVisibleSignal() && !this.isTemporaryHidden()) {
      this.makeInvisibleAndUpdateSettings();
    }
  }

  onOpenNpsSurvey(): void {
    window.open('https://www.surveymonkey.com/r/FDTNPSQ32024', '_blank', 'noopener')?.focus();
    this.layoutSettings.npsSurvey = true;
    this.saveSettings();
    this.makeInvisible();
  }

  onClosePopup(): void {
    this.makeInvisibleAndUpdateSettings();
  }

  private makeInvisibleAndUpdateSettings(): void {
    this.layoutSettings.npsSessionSkiped++;
    this.layoutSettings.lastNpsSession = this.logoutService.getAuthTime();
    this.saveSettings();
    this.makeInvisible();
  }

  private makeInvisible(): void {
    this.isVisibleSignal.set(false);
  }

  private saveSettings(): void {
    this.appSettingsService.saveSettings('layout', this.layoutSettings).subscribe();
  }

  /**
   * Increase the session skiped count if needed
   * This is needed when we are between the dates and the nps survey was not clicked
   * BUT we hide it because not third session AND last session is different from current session
   * In that case we need to increase the session skiped count and save the settings
   * @see {@link https://1stdigital.atlassian.net/browse/STX-3492}
   */
  private increaseSessionSkipedIfNeeded(): void {
    const info = this.getVisibilityInfo();

    if (!info.showEveryThirdSession && info.isBetween && !this.layoutSettings.npsSurvey && info.differentSessions) {
      // if we don't update the session, refresh will increase session skiped :D
      this.layoutSettings.lastNpsSession = this.logoutService.getAuthTime();
      this.layoutSettings.npsSessionSkiped++;
      this.saveSettings();
    }
  }

  private isInitialyVisible(): boolean {
    const info = this.getVisibilityInfo();

    return info.isBetween && !this.layoutSettings.npsSurvey && info.showEveryThirdSession;
  }

  /**
   * Calculate the visibility information
   * @returns VisibilityInfo
   */
  private getVisibilityInfo(): VisibilityInfo {
    const today = new Date();
    const showFrom = new Date('2024-09-16T00:00:00');
    const showTo = new Date('2024-09-30T23:59:59');

    const isBetween = today >= showFrom && today <= showTo;
    const showEveryThirdSession = this.layoutSettings.npsSessionSkiped % 3 === 0;
    const differentSessions = this.layoutSettings.lastNpsSession !== this.logoutService.getAuthTime();

    return { isBetween, showEveryThirdSession, differentSessions };
  }
}

/**
 * Internal interface to hold the visibility information/calculation
 */
interface VisibilityInfo {
  /**
   * Flag for-> If the current date is between the 2024-09-16 and 2024-09-30 dates
   */
  isBetween: boolean;

  /**
   * Flag for -> Popup should be shown every third session,
   */
  showEveryThirdSession: boolean;

  /**
   * Flag for -> If the last session is different from the current session
   */
  differentSessions: boolean;
}
