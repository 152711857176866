import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import {
  FdtAmountInputComponent,
  FdtDropdownModule,
  FdtInputComponent,
  FdtNumberInputComponent,
  FdtTextareaComponent,
} from '@1stdigital/ng-sdk/controls';
import { FdtFileUploadComponent } from '@1stdigital/ng-sdk/file-upload';
import { FdtFormFieldGroupComponent, FdtFormFieldModule } from '@1stdigital/ng-sdk/form-field';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { FdtIconFrameComponent, FdtSpinnerOverlayDirective } from '@1stdigital/ng-sdk/shared';
import { AutoFocusDirective } from '@app/shared/directives/auto-focus.directive';
import { StepComponent } from '@app/shared/stepper-group/step/step.component';
import { StepperGroupComponent } from '@app/shared/stepper-group/stepper-group.component';

const form = [ReactiveFormsModule, FdtFormFieldModule, FdtButtonModule];

const controls = [
  FdtDropdownModule,
  FdtInputComponent,
  FdtTextareaComponent,
  FdtFileUploadComponent,
  FdtAmountInputComponent,
  FdtNumberInputComponent,
  FdtFormFieldGroupComponent,
];

const helpers = [
  FdtSpinnerOverlayDirective,
  NgIf,
  FdtIconModule,
  FdtIconFrameComponent,
  StepperGroupComponent,
  StepComponent,
  AutoFocusDirective,
];

/**
 *
 * Helper module to group all form related components and modules;
 * This is useful to make importing shorter;
 */
@NgModule({
  declarations: [],
  imports: [...form, ...controls, ...helpers],
  providers: [],
  exports: [...form, ...controls, ...helpers],
})
export class AppFormModule {}
