import { CdkStep, CdkStepperModule, STEP_STATE, StepState } from '@angular/cdk/stepper';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { MatExpansionModule } from '@angular/material/expansion';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { Subscription } from 'rxjs';

import { StepperGroupComponent } from '../stepper-group.component';

@Component({
  selector: 'app-step',
  imports: [MatExpansionModule, FdtButtonModule, ReactiveFormsModule, CdkStepperModule, NgTemplateOutlet],
  templateUrl: './step.component.html',
  styleUrl: './step.component.scss',
  providers: [{ provide: CdkStep, useExisting: StepComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepComponent extends CdkStep implements OnChanges, OnDestroy {
  @ContentChild('nextActionTemplateRef') nextActionTemplateRef!: TemplateRef<unknown>;
  @ContentChild('editActionsTemplateRef') editActionsTemplateRef!: TemplateRef<unknown>;

  @Output() next = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Input() header!: string;
  @Input() isBusy: boolean = false;
  @Input() disabled = false;

  override readonly _stepper!: StepperGroupComponent;

  get stepDisabled(): boolean {
    return this.stepControl?.invalid || this.disabled || this.stepControl?.pending;
  }

  get isLastStep(): boolean {
    return this._stepper.steps.last === this;
  }

  get isEdit(): boolean {
    return this.state === STEP_STATE.EDIT;
  }

  get isDone(): boolean {
    return this.state === STEP_STATE.DONE;
  }

  get expanded(): boolean {
    return !this.disabled && this.isDone;
  }

  // previous step control value, used to restore the form value when cancel
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previousValue: any = {};

  controlStateSubscription?: Subscription;

  constructor(
    @Inject(forwardRef(() => StepperGroupComponent)) stepper: StepperGroupComponent,
    private readonly cdr: ChangeDetectorRef
  ) {
    super(stepper);
  }

  override ngOnChanges(): void {
    super.ngOnChanges();

    this.controlStateSubscription?.unsubscribe();

    if (this.stepControl) {
      this.controlStateSubscription = this.stepControl.statusChanges.subscribe(() => this.cdr.markForCheck());
    }
  }

  ngOnDestroy(): void {
    this.controlStateSubscription?.unsubscribe();
  }

  private emitNext(): void {
    this.next.emit(this.isEdit);
    this.setState(STEP_STATE.DONE);

    if (this.stepControl instanceof FormGroup) {
      // getRawValue for get values from disabled controls
      this.previousValue = this.stepControl?.getRawValue();
    } else {
      // Had issue with nested controls with same controlName as parent, seems this fixed the issue e.g { bankName:'', otherBank: { bankName: '' }}
      this.previousValue = this.stepControl ? JSON.parse(JSON.stringify(this.stepControl.value)) : {};
    }

    this._stepper.editStep = null;
    this.stepControl?.markAsPristine();
  }

  setState(state: StepState): void {
    this.state = state;

    if (this.state === STEP_STATE.EDIT) {
      this.edit.emit();
    }
  }

  onNext(): void {
    this.emitNext();
    this._stepper.next();
    this._stepper.lastSelectedStep = this._stepper.selected;
  }

  onUpdate(): void {
    this.emitNext();
    this._stepper.lastSelectedStep!.select();
  }

  onCancel(): void {
    this.resetStepControl();
    this.setState(STEP_STATE.DONE);
    this._stepper.lastSelectedStep!.select();
    this._stepper.editStep = null;
    this.cancel.emit();
  }

  private resetStepControl(): void {
    if (!this.stepControl) {
      return;
    }

    // Had issue using the reset method with nested groups
    const controls = (this.stepControl as FormGroup).controls;
    Object.keys(controls).forEach((controlName: string) => {
      // hidden controls that are not included in the form value
      if (!this.previousValue[controlName]) {
        this.previousValue[controlName] = null;
      }
    });
    this.stepControl.setValue(this.previousValue, { emitEvent: false }); // Avoid emitting change event
    this.stepControl.markAsPristine();

    // Using form.reset() works fine with controls that are not part of the formValue. but with reset we had issues with nested controls of same name as in parent.
    // Set value and setting all controls that are missing in the formValue feels hacky, ideal solution is to use reset, and refactor the whitelist modal. Would require careful refactor in whitelist due to the complexity of the component and models
  }
}
