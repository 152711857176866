@switch (type) {
  @case ('fiat') {
    <app-fiat-instruction-update-text [eventId]="eventId" [requestData]="fiatInstructionUpdateData" />
  }
  @case ('crypto') {
    <app-crypto-instruction-update-text [eventId]="eventId" [requestData]="cryptoInstructionUpdateData" />
  }
  @case ('rsn') {
    <app-rsn-instruction-update-text [eventId]="eventId" [requestData]="rsnInstructionUpdateData" />
  }
  @case ('otc') {
    <app-otc-instruction-update-text [eventId]="eventId" [requestData]="otcInstructionUpdateData" />
  }
}
