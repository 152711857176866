import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { InboxWalletWhitelistingData, NotificationType } from '@app/core/models/interfaces/inbox.interface';

@Component({
  selector: 'app-inbox-wallet-whitelisting',
  imports: [],
  templateUrl: './inbox-wallet-whitelisting.component.html',
  styleUrl: './inbox-wallet-whitelisting.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxWalletWhitelistingComponent {
  @Input({ required: true }) eventId!: NotificationType;
  @Input({ required: true }) requestData!: InboxWalletWhitelistingData;
}
