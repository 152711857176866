import { Component, Input } from '@angular/core';

import { NotificationType, OtcInstructionUpdateData } from '@app/core/models/interfaces/inbox.interface';

@Component({
  selector: 'app-otc-instruction-update-text',
  templateUrl: './otc-instruction-update-text.component.html',
})
export class OtcInstructionUpdateTextComponent {
  @Input() eventId!: NotificationType;
  @Input() requestData!: OtcInstructionUpdateData;
}
