<app-announcement></app-announcement>
<mat-sidenav-container class="mat-body-1">
  <mat-sidenav
    [mode]="isMobileView ? 'over' : 'side'"
    [position]="isMobileView ? 'end' : 'start'"
    [opened]="isNavOpened"
    (openedChange)="onOpenedChange($event)"
  >
    <div class="sidebar">
      <div class="sidebar__nav">
        @if (isMobileView) {
          <div class="sidebar__close-button">
            <button fdt-fab-button color="tertiary" size="large" (click)="onCloseNav()">
              <fdt-icon name="closeLine" size="24"></fdt-icon>
            </button>
          </div>
        } @else {
          <!-- Logo -->
          <div class="sidebar__banner">
            <button fdt-ghost-button appTrackEvent="Back to portal clicked">
              <img routerLink="/" class="sidebar__logo" [src]="'assets/images/logo-symbol-dark.svg'" alt="Logo" />
            </button>
            @if (isInboxEnabled) {
              <app-inbox-widget [hasNewMessage]="hasNewMessageSignal()"></app-inbox-widget>
            }
          </div>
        }

        <!-- Client Info -->
        <div class="sidebar__info" appTourHighlight [th-tour-config]="{ step: 1, name: 'onboarding' }">
          <button
            class="sidebar__info-wrapper"
            (click)="onOpenEntitySwitcherClick()"
            appTrackEvent="Client service switcher clicked"
          >
            <div class="sidebar__info-header">
              <div>
                @if (client$ | async; as client) {
                  <div class="info-name">{{ client.name }}</div>
                }
                @if (serviceEntity$ | async; as serviceEntity) {
                  <div class="info-entity">{{ serviceEntity.name }}</div>
                }
              </div>
              <div class="fdt-arrow-icon">
                <fdt-icon name="arrowRightSFill"></fdt-icon>
              </div>
            </div>
          </button>
          <button
            routerLink="/client"
            class="sidebar__info-description"
            appTrackEvent="Users and client info clicked"
            (click)="onCloseNav()"
          >
            Users & Client Info
          </button>
        </div>

        <!-- Navigation -->
        <nav aria-label="Main Navigation">
          @for (navItem of navItems; track navItem.label) {
            @if (navItem.children && navItem.children.length > 0 && !navItem.hidden) {
              <div appTourHighlight [th-tour-config]="navItem.tourConfig">
                <div class="sidebar__navigation-title">{{ navItem.label }}</div>
                @for (childItem of navItem.children; track childItem.label) {
                  <ng-container *ngTemplateOutlet="navigationItem; context: { $implicit: childItem }"></ng-container>
                }
              </div>
            } @else {
              <ng-container *ngTemplateOutlet="navigationItem; context: { $implicit: navItem }"></ng-container>
            }
          }
        </nav>
      </div>

      <ng-template #navigationItem let-navItem>
        @if (navItem.hidden === undefined || !navItem.hidden) {
          <div appProtectIfNoPermission npStrategy="hide">
            <a
              class="sidebar__navigation-item"
              [routerLink]="navItem.link"
              routerLinkActive="active"
              [appTrackEvent]="navItem.analyticEvent"
              (click)="onCloseNav()"
              appTourHighlight
              [th-tour-config]="navItem.tourConfig"
            >
              <fdt-icon class="navigation-icon" size="20" [name]="navItem.icon"></fdt-icon>
              <div class="navigation-label">{{ navItem.label }}</div>
            </a>
          </div>
        }
      </ng-template>

      <!-- Footer -->
      <div class="sidebar__footer">
        <nav aria-label="Footer Navigation" appProtectIfNoPermission npStrategy="hide">
          <a
            class="sidebar__navigation-item"
            routerLink="/whitelist"
            routerLinkActive="active"
            appTrackEvent="Whitelist clicked"
            (click)="onCloseNav()"
            appTourHighlight
            [th-tour-config]="{ step: 5, name: 'onboarding' }"
          >
            <fdt-icon size="20" name="contactsBook3Line"></fdt-icon>
            <div class="navigation-label">Whitelist</div>
          </a>
        </nav>
        <div class="sidebar__footer__divider"></div>
        <div
          [matMenuTriggerFor]="menu"
          #menuTrigger="matMenuTrigger"
          [class.active]="menuTrigger.menuOpen"
          class="sidebar__navigation-item fdt-export-dropdown"
          #trigger
        >
          <div class="dropdown">
            <div class="dropdown-label">
              <fdt-icon size="20" name="userLine"></fdt-icon>
              <div class="navigation-label">{{ name }}</div>
            </div>
            <button class="fdt-arrow-icon-inverted">
              <fdt-icon name="arrowDownSFill"></fdt-icon>
            </button>
          </div>
        </div>
        <mat-menu
          #menu="matMenu"
          yPosition="above"
          xPosition="after"
          class="sidebar__dropdown-menu fdt-export-dropdown-menu"
        >
          <a
            routerLink="/profile"
            class="sidebar__dropdown-menu-item fdt-export-dropdown-menu-item"
            mat-menu-item
            appTrackEvent="User profile clicked"
            (click)="onCloseNav()"
          >
            <fdt-icon size="20" name="userLine"></fdt-icon>
            Profile
          </a>
          <button
            class="sidebar__dropdown-menu-item fdt-export-dropdown-menu-item"
            mat-menu-item
            appTourHighlight
            [th-tour-config]="{ step: 1, name: 'relaunch-onboarding' }"
            [th-prerequisite-click-ele]="trigger"
            (click)="onOnboardingStart()"
            appTrackEvent="Tour side menu clicked"
          >
            <fdt-icon size="20" name="graduationCapLine"></fdt-icon>
            Onboarding Tour
          </button>
          <a
            routerLink="/help-center"
            class="sidebar__dropdown-menu-item fdt-export-dropdown-menu-item"
            mat-menu-item
            (click)="onCloseNav()"
            appTrackEvent="Help center clicked"
          >
            <fdt-icon size="20" name="chat1Line"></fdt-icon>
            Help Center
          </a>
          <button
            class="sidebar__dropdown-menu-item fdt-export-dropdown-menu-item"
            mat-menu-item
            (click)="onLogout()"
            appTrackEvent="Logout"
          >
            Logout
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-sidenav>

  <!-- Main Content -->
  <mat-sidenav-content>
    <!--
      Needed for cdkOverlay scroll strategies
      Ref: https://briantree.se/angular-cdk-overlay-tutorial-scroll-strategies/
    -->
    <main cdkScrollable>
      @if (isMobileView) {
        <div class="sidebar__mobile">
          <!-- below empty div will be a notification icon coming in future sprints -->
          @if (isInboxEnabled) {
            <app-inbox-widget [hasNewMessage]="hasNewMessageSignal()"></app-inbox-widget>
          }
          <img routerLink="/" class="sidebar__mobile-logo" [src]="'assets/images/logo-symbol-dark.svg'" alt="Logo" />
          <button class="sidebar__mobile-hamburger" (click)="onToggleSidebar()">
            <fdt-icon size="20" name="menuLine"></fdt-icon>
          </button>
        </div>
      }
      <router-outlet></router-outlet>
      @if (showHelpWidgetSignal()) {
        <app-help-widget
          appProtectIfNoPermission
          npStrategy="hide"
          [permissionSectionName]="permissionSectionName"
        ></app-help-widget>
      }
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-nps-survey-popup></app-nps-survey-popup>
