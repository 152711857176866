import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';

import { ButtonComponent } from '@1stdigital/ng-sdk/button';
import { AnalyticsEvents } from '@app/core/models/analytics-events.type';
import { AnalyticsService, ApplicationSettingsService } from '@app/core/services';
import { LogoutService } from '@app/core/services/logout.service';
import { LayoutModuleSettings } from '@app/layout/layout-module.settings';
import { ActiveTourState } from '@app/tour/models/tour.interface';
import { TourContentArrowDirection } from '@app/tour/models/tour-content-arrow-direction.type';
import { TourService } from '@app/tour/tour.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tour-step-content',
  imports: [ButtonComponent, AsyncPipe],
  templateUrl: './tour-step-content.component.html',
  styleUrl: './tour-step-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourStepContentComponent {
  arrowDirectionSignal = input<TourContentArrowDirection | null>(null, { alias: 'arrowDirection' });
  arrowOffsetSignal = input<number | null>(null, { alias: 'arrowOffset' });

  arrowPositioningStyle: Signal<Record<string, unknown> | null> = computed(() => {
    const verticalAlignDirections: TourContentArrowDirection[] = ['left', 'right'];
    const currentArrowDirection = this.arrowDirectionSignal();

    if (!currentArrowDirection) {
      return null;
    }

    const offset = `${this.arrowOffsetSignal()}px`;

    if (verticalAlignDirections.includes(currentArrowDirection)) {
      return { top: offset };
    }

    return { left: offset };
  });

  activeTourState$: Observable<ActiveTourState | null>;
  constructor(
    private readonly tourService: TourService,
    private readonly appSettingsService: ApplicationSettingsService,
    private readonly logoutService: LogoutService,
    private readonly analyticsService: AnalyticsService
  ) {
    this.activeTourState$ = this.tourService.activeTourState$;
  }

  onNext(): void {
    const activeTourState = this.tourService.activeTourState;

    if (activeTourState && activeTourState.tour.name === 'onboarding') {
      this.analyticsService.track('Next for tour clicked', {
        tourStepTitle: activeTourState.step.title,
        tourStepIndex: activeTourState.step.id,
      });
    }

    this.tourService.nextStep();
  }

  onBack(): void {
    const activeTourState = this.tourService.activeTourState;

    if (activeTourState && activeTourState.tour.name === 'onboarding') {
      this.analyticsService.track('Back for tour clicked', {
        tourStepTitle: activeTourState.step.title,
        tourStepIndex: activeTourState.step.id,
      });
    }

    this.tourService.previousStep();
  }

  onComplete(): void {
    const activeTourState = this.tourService.activeTourState;

    if (activeTourState) {
      let trackEventName: AnalyticsEvents = 'Next for tour clicked';

      if (activeTourState.tour.name === 'relaunch-onboarding') {
        trackEventName = 'Relaunch tour CTA clicked';
      }

      this.analyticsService.track(trackEventName, {
        tourStepTitle: activeTourState.step.title,
        tourStepIndex: activeTourState.step.id,
      });
    }

    this.tourService.endTour(true);
  }

  onTakeTourLater(): void {
    const activeTourState = this.tourService.activeTourState;

    if (activeTourState && activeTourState.tour.name === 'onboarding') {
      this.analyticsService.track('Take tour later in steps clicked', {
        tourStepTitle: activeTourState.step.title,
        tourStepId: activeTourState.step.id,
      });
    }

    this.tourService.endTour();

    const layoutSettings = this.appSettingsService.getSettings<LayoutModuleSettings>('layout');
    const tourState = layoutSettings.tourGuide['relaunch-onboarding'];

    if (!tourState || (!tourState.finish && tourState.lastSessionId !== this.logoutService.getAuthTime())) {
      this.tourService.addTourToQueue('relaunch-onboarding');
    }
  }
}
