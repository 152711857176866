import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';

import { ButtonComponent } from '@1stdigital/ng-sdk/button';
import { ApplicationSettingsService } from '@app/core/services';
import { LogoutService } from '@app/core/services/logout.service';
import { LayoutModuleSettings } from '@app/layout/layout-module.settings';
import { TrackEventDirective } from '@app/shared/directives/track-event.directive';
import { TourName } from '@app/tour/models/tour.interface';
import { TourChangeStates } from '@app/tour/models/tour-change-state';
import { TourService } from '@app/tour/tour.service';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrl: './welcome-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TrackEventDirective],
})
export class WelcomeModalComponent implements OnDestroy {
  private tourService = inject(TourService);
  private dialogRef = inject(DialogRef);
  private appSettingsService = inject(ApplicationSettingsService);
  private logoutService = inject(LogoutService);

  onTakeTourLater(): void {
    const tourName: TourName = 'relaunch-onboarding';
    const layoutSettings = this.appSettingsService.getSettings<LayoutModuleSettings>('layout');
    const tourState = layoutSettings?.tourGuide?.[tourName];

    // skip manually since this modal is not part of the tour for now;
    this.tourService.updateTourSettings(TourChangeStates.Skipped, 'onboarding');

    if (!tourState || (!tourState.finish && tourState.lastSessionId !== this.logoutService.getAuthTime())) {
      this.tourService.addTourToQueue('relaunch-onboarding', true);
    }

    this.dialogRef.close();
  }

  onStartTour(): void {
    // Petru: welcome modal will not be opened if onboarding tour is finished, so no need for check here;
    this.tourService.addTourToQueue('onboarding', true);
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.tourService.endTourIntro();
  }
}
