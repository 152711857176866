import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import {
  CryptoInstructionUpdateData,
  FiatInstructionUpdateData,
  InboxMessageRequestData,
  NotificationType,
  OtcInstructionUpdateData,
  RsnInstructionUpdateData,
} from '@app/core/models/interfaces/inbox.interface';

import { CryptoInstructionUpdateTextComponent } from './crypto-instruction-update-text/crypto-instruction-update-text.component';
import { FiatInstructionUpdateTextComponent } from './fiat-instruction-update-text/fiat-instruction-update-text.component';
import { OtcInstructionUpdateTextComponent } from './otc-instruction-update-text/otc-instruction-update-text.component';
import { RsnInstructionUpdateTextComponent } from './rsn-instruction-update-text/rsn-instruction-update-text.component';

@Component({
  selector: 'app-inbox-instruction-update-text',
  templateUrl: './inbox-instruction-update-text.component.html',
  styleUrls: ['./inbox-instruction-update-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FiatInstructionUpdateTextComponent,
    CryptoInstructionUpdateTextComponent,
    RsnInstructionUpdateTextComponent,
    OtcInstructionUpdateTextComponent,
  ],
})
export class InboxInstructionUpdateTextComponent implements OnChanges {
  fiatInstructionUpdateData!: FiatInstructionUpdateData;
  cryptoInstructionUpdateData!: CryptoInstructionUpdateData;
  rsnInstructionUpdateData!: RsnInstructionUpdateData;
  otcInstructionUpdateData!: OtcInstructionUpdateData;

  @Input({ required: true }) eventId!: NotificationType;
  @Input({ required: true }) requestData!: InboxMessageRequestData;

  ngOnChanges(): void {
    this.updateInstructionData(this.type);
  }

  get type(): InstructionUpdateType {
    if (this.eventId.startsWith('ams.instruction.crypto')) {
      return 'crypto';
    } else if (this.eventId.startsWith('ams.instruction.fiat')) {
      return 'fiat';
    } else if (this.eventId.startsWith('ams.instruction.rsn')) {
      return 'rsn';
    } else if (this.eventId.startsWith('ams.instruction.otc')) {
      return 'otc';
    }

    return 'scheduled';
  }

  updateInstructionData(type: InstructionUpdateType): void {
    switch (type) {
      case 'fiat':
        this.fiatInstructionUpdateData = this.requestData as FiatInstructionUpdateData;
        break;
      case 'crypto':
        this.cryptoInstructionUpdateData = this.requestData as CryptoInstructionUpdateData;
        break;
      case 'rsn':
        this.rsnInstructionUpdateData = this.requestData as RsnInstructionUpdateData;
        break;
      case 'otc':
        this.otcInstructionUpdateData = this.requestData as OtcInstructionUpdateData;
        break;
    }
  }
}

type InstructionUpdateType = 'fiat' | 'crypto' | 'rsn' | 'otc' | 'scheduled';
