import { Injectable } from '@angular/core';

import { BaseApiService } from '@1stdigital/ng-sdk/core';
import { Observable } from 'rxjs';

import { UserInfoResponse } from '../models/interfaces/user-info.interface';

@Injectable({
  providedIn: 'root',
})
export class UserInfoApiService extends BaseApiService {
  getUserInfo(): Observable<UserInfoResponse> {
    return this.get<UserInfoResponse>('user-information-on-client');
  }
}
