import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatDialogRef } from '@angular/material/dialog';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { FdtFormFieldModule, FdtHintDirective } from '@1stdigital/ng-sdk/form-field';
import { EnvironmentLoaderService } from '@app/core/services';
import { HelpCenterApiService } from '@app/help-center/help-center-api.service';
import { TrackEventDirective } from '@app/shared/directives/track-event.directive';
import { AppFormModule } from '@app/shared/form/form.module';
import { InfoBlockComponent } from '@app/shared/info-block/info-block.component';
import { finalize } from 'rxjs';

import { SnackBarService } from '../../../../notifications/snack-bar.service';
import { makeLink } from '../../../../notifications/utils';

@Component({
  selector: 'app-create-help-ticket',
  imports: [
    InfoBlockComponent,
    FdtButtonModule,
    FdtFormFieldModule,
    AppFormModule,
    FdtHintDirective,
    TrackEventDirective,
  ],
  templateUrl: './create-help-ticket-modal.component.html',
  styleUrl: './create-help-ticket-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateHelpTicketModalComponent {
  @Output() ticketCreated = new EventEmitter();

  isBusy: boolean = false;
  ticketForm = this.initForm();
  readonly helpDeskUrl = this.envService.environment['helpDeskUrl'] as string;

  get isButtonDisabled(): boolean {
    return this.ticketForm.invalid || this.isBusy;
  }

  get showCloseButton(): boolean {
    return !!this.dialogRef;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private helpCenterApiService: HelpCenterApiService,
    private snackBarService: SnackBarService,
    private cdr: ChangeDetectorRef,
    private envService: EnvironmentLoaderService,
    @Optional() private dialogRef?: MatDialogRef<CreateHelpTicketModalComponent>
  ) {}

  onCreateTicketClick(): void {
    if (!this.ticketForm.valid) {
      return;
    }

    const body = {
      subject: this.ticketForm.controls.subject.value,
      message: this.ticketForm.controls.message.value,
    };

    this.isBusy = true;
    this.helpCenterApiService
      .createTicket(body)
      .pipe(
        finalize(() => {
          this.isBusy = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe({
        next: ({ ticketId }) => this.onCreateTicketSuccess(ticketId),
        error: () => this.onCreateTicketFailure(),
      });
  }

  onModalClose(): void {
    this.dialogRef?.close();
  }

  private initForm(): FormGroup<CreateTicketForm> {
    return this.fb.nonNullable.group({
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }

  private onCreateTicketSuccess(ticketId: number): void {
    this.snackBarService.showSuccess({
      message: 'Ticket has been created',
      caption: 'Our team will be in touch with you shortly',
    });
    this.router.navigate(['help-center', ticketId]).then(() => {
      this.ticketForm.reset();
      this.ticketCreated.emit();
      this.dialogRef?.close();
    });
  }

  private onCreateTicketFailure(): void {
    this.snackBarService.showError({
      message: 'Ticket hasn’t been submitted',
      caption: `Please try again, or if the issue persists, reach out to our ${makeLink('customer support team', { href: 'mailto:cs@1stdigital.com' })}`,
    });
  }
}

export interface CreateTicketForm {
  subject: FormControl<string>;
  message: FormControl<string>;
}
