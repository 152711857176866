import { Component, Input } from '@angular/core';

import { NotificationType, RsnInstructionUpdateData } from '@app/core/models/interfaces/inbox.interface';

@Component({
  selector: 'app-rsn-instruction-update-text',
  templateUrl: './rsn-instruction-update-text.component.html',
})
export class RsnInstructionUpdateTextComponent {
  @Input() eventId!: NotificationType;
  @Input() requestData!: RsnInstructionUpdateData;
}
