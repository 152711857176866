@switch (eventId) {
  @case ('ams.instruction.otc.initiated.client') {
    OTC instruction created: {{ requestData.sourceAmount }} {{ requestData.sourceCurrencySymbol }} to
    {{ requestData.destinationAmount }} {{ requestData.destinationCurrencySymbol }} (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.otc.completed.client') {
    OTC instruction for {{ requestData.sourceAmount }} {{ requestData.sourceCurrencySymbol }} to
    {{ requestData.destinationAmount }} {{ requestData.destinationCurrencySymbol }} is complete (Ref:
    {{ requestData.referenceNumber }}).
  }
}
