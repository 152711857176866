import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { ConfirmModalConfig } from '@1stdigital/ng-sdk/dialog';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';

@Component({
  selector: 'app-confirmation-modal',
  imports: [FdtIconModule, FdtButtonModule],
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent {
  config: ConfirmModalConfig = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConfirmModalConfig,
    private dialogRef: MatDialogRef<ConfirmationModalComponent, boolean>
  ) {
    this.config = data;
  }

  onCancelModal(): void {
    this.dialogRef.close(false);
  }

  onSubmitModal(): void {
    this.dialogRef.close(true);
  }
}
