import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { filter, map, Observable, take } from 'rxjs';

import { EnvironmentLoaderService } from '../services';
import { UserPermissionService } from '../services/user-permission.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  constructor(
    private readonly environmentLoaderService: EnvironmentLoaderService,
    private readonly userPermissionService: UserPermissionService,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.userPermissionService.init();

    return this.userPermissionService.ready$.pipe(
      filter((ready) => !!ready),
      take(1),
      map(() => {
        if (!this.environmentLoaderService.environment['enableRolePolicy']) {
          return true;
        }

        const hasPermission = this.userPermissionService.hasPermission(state.url.split('?')[0]);

        if (!hasPermission) {
          this.redirectToUnauthorized();
          return false;
        }

        return hasPermission;
      })
    ) as Observable<boolean>;
  }

  private redirectToUnauthorized(): void {
    this.router.navigate(['/403']);
  }
}
