import { Component, Input } from '@angular/core';

import { FiatInstructionUpdateData, NotificationType } from '@app/core/models/interfaces/inbox.interface';

@Component({
  selector: 'app-fiat-instruction-update-text',
  templateUrl: './fiat-instruction-update-text.component.html',
})
export class FiatInstructionUpdateTextComponent {
  @Input() eventId!: NotificationType;
  @Input() requestData!: FiatInstructionUpdateData;
}
