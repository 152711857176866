import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { CdkScrollable } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterModule, RouterOutlet } from '@angular/router';

import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';

import { ButtonComponent } from '@1stdigital/ng-sdk/button';
import { AppUserService, IconName } from '@1stdigital/ng-sdk/core';
import { DialogService } from '@1stdigital/ng-sdk/dialog';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { AnalyticsEvents } from '@app/core/models/analytics-events.type';
import { OPA_FORM_LIST } from '@app/core/models/opa';
import { ApplicationSettingsService, InboxStoreService } from '@app/core/services';
import { ClientService } from '@app/core/services/client.service';
import { ClientsAccessorApiService } from '@app/core/services/clients-accessor-api.service';
import { DocumentTypesStore } from '@app/core/services/document-types.store';
import { EnvironmentLoaderService } from '@app/core/services/environment-loader.service';
import { LogoutService } from '@app/core/services/logout.service';
import { UserInfoStore } from '@app/core/services/user-info.store';
import { UserPermissionService } from '@app/core/services/user-permission.service';
import { HelpWidgetService } from '@app/help-center/help-widget.service';
import { HelpWidgetComponent } from '@app/help-center/shared/components/help-widget/help-widget.component';
import { LayoutModuleSettings } from '@app/layout/layout-module.settings';
import { NpsSurveyPopupComponent } from '@app/layout/nps-survey-popup/nps-survey-popup.component';
import { ProtectIfNoPermissionDirective } from '@app/shared/directives/protect-if-no-permission.directive';
import { TrackEventDirective } from '@app/shared/directives/track-event.directive';
import { TourHighlightConfig, TourName } from '@app/tour/models/tour.interface';
import { TourService } from '@app/tour/tour.service';
import { TourHighlightDirective } from '@app/tour/tour-highlight.directive';
import { WelcomeModalComponent } from '@app/tour/welcome-modal/welcome-modal.component';
import { MsalService } from '@azure/msal-angular';
import { TokenClaims } from '@azure/msal-common';
import { EMPTY, filter, forkJoin, pairwise, skip, startWith, switchMap } from 'rxjs';
import { InboxWidgetComponent } from 'src/app/inbox/inbox-widget/inbox-widget.component';

import { AnnouncementComponent } from '../announcement/announcement.component';

interface NavItem {
  label: string;
  icon: IconName;
  link?: string;
  hidden?: boolean;
  analyticEvent?: AnalyticsEvents;
  tourConfig?: TourHighlightConfig;
  children?: NavItem[];
}

@Component({
  selector: 'app-layout',
  imports: [
    CommonModule,
    MatSidenavModule,
    MatMenuModule,
    RouterOutlet,
    RouterModule,
    FdtIconModule,
    AnnouncementComponent,
    HelpWidgetComponent,
    CdkScrollable,
    ButtonComponent,
    InboxWidgetComponent,
    TrackEventDirective,
    TourHighlightDirective,
    NpsSurveyPopupComponent,
    ProtectIfNoPermissionDirective,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit, OnDestroy {
  client$ = this.clientService.activeClient$;
  serviceEntity$ = this.clientService.activeServiceEntity$;
  showHelpWidgetSignal = this.helpWidgetService.isVisibleSignal;

  unreadMessagesVMSignal = toSignal(this.inboxStore.unreadMessageStore$, { requireSync: true });
  hasNewMessageSignal = computed(() => this.unreadMessagesVMSignal().totalCount > 0);

  name?: string;
  isMobileView = false;
  isNavOpened = true;
  isInboxEnabled = !!this.envService.environment['enableInbox'];
  userInfoSignal = toSignal(this.userInfoStore.userInfo$);

  navItems: NavItem[] = [];
  readonly permissionSectionName = OPA_FORM_LIST.Helpcenter.Tickets_Form;

  constructor(
    private readonly envService: EnvironmentLoaderService,
    private readonly router: Router,
    private readonly authService: MsalService,
    private readonly helpWidgetService: HelpWidgetService,
    private readonly logoutService: LogoutService,
    private readonly clientService: ClientService,
    private readonly clientsAccessorApiService: ClientsAccessorApiService,
    private readonly userInfoStore: UserInfoStore,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly appUserService: AppUserService,
    private readonly dialogService: DialogService,
    private readonly tourService: TourService,
    private readonly appSettingsService: ApplicationSettingsService,
    private readonly destroyRef: DestroyRef,
    private readonly inboxStore: InboxStoreService,
    private readonly documentTypesStore: DocumentTypesStore,
    private readonly cdr: ChangeDetectorRef,
    private readonly userPermissionService: UserPermissionService
  ) {
    this.handleToursOpening();
    this.handleTourListeners();
  }

  ngOnInit(): void {
    const idTokenClaims: TokenClaims | undefined = this.authService.instance.getActiveAccount()?.idTokenClaims;
    this.name = idTokenClaims?.name;
    this.setNavItems();

    this.listenOnClientChange();
    this.documentTypesStore.getDocumentTypes().subscribe();

    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe((result: BreakpointState) => {
        this.isMobileView = result.matches;
        this.isNavOpened = !result.matches;
        this.cdr.markForCheck();
      });

    if (this.isInboxEnabled) {
      this.clientService.activeClient$
        .pipe(
          startWith(null),
          pairwise(),
          switchMap(([previousClient, currentClient]) => {
            // If the client changes, restart the WebSocket connection using the new clientId and fetch initial notifications
            if (currentClient && (!previousClient || previousClient.id !== currentClient.id)) {
              this.inboxStore.disconnectWs();
              this.inboxStore.initWebSocket(`${currentClient.id}`);

              return this.inboxStore.getMessages('unread');
            }

            return EMPTY;
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    }
  }

  private setNavItems(): void {
    this.navItems = [
      {
        label: 'Overview',
        icon: 'home3Line',
        link: '/dashboard',
        analyticEvent: 'Overview clicked',
      },
      {
        label: 'Asset Holdings',
        icon: 'pieChartLine',
        link: '/asset-holdings',
        analyticEvent: 'Asset holdings clicked',
        tourConfig: { step: 2, name: 'onboarding' },
      },
      {
        label: 'Activity',
        icon: 'historyLine',
        link: '/activity',
        analyticEvent: 'Activity clicked',
        tourConfig: { step: 3, name: 'onboarding' },
      },
      {
        label: 'Cards',
        icon: 'bankCard2Line',
        link: '/card',
        hidden: !this.canShowCardMenuItem(),
        analyticEvent: 'FDT Card clicked',
      },
      {
        label: 'Instructions',
        icon: 'home3Fill',
        hidden: !this.userPermissionService.shouldShowInstructions(),
        tourConfig: { step: 4, name: 'onboarding' },
        children: [
          {
            label: 'Asset Transfer',
            icon: 'arrowUpDownLine',
            link: '/asset-transfer',
            analyticEvent: 'Asset transfer clicked',
          },
          {
            label: 'OTC',
            icon: 'loopLeftLine',
            link: '/otc',
            analyticEvent: 'Digital asset exchange clicked',
          },
        ],
      },
      // {
      //   label: 'Treasury Bills',
      //   icon: 'redPacketLine',
      //   link: '/treasury-bills',
      // },
    ];
  }

  private listenOnClientChange(): void {
    this.clientService.activeServiceEntity$
      .pipe(
        skip(1), // initially resolved in resolver
        switchMap(() => {
          return forkJoin([this.clientsAccessorApiService.getAssetMasterIds(), this.userInfoStore.getUserInfo()]);
        })
      )
      .subscribe(([assetMasterIds]) => {
        this.appUserService.setAppAvailableAssets(assetMasterIds);
        // After client is changed, we need to re-check displayed navItems
        this.setNavItems();
        this.cdr.detectChanges();
      });
  }

  private canShowCardMenuItem(): boolean {
    if (this.envService.environment['enableCard']) {
      const cardApplication = !!this.envService.environment['cardApplication'];

      if (!cardApplication) {
        return !!(this.userInfoSignal()?.isCardHolder || this.userInfoSignal()?.isCardAdmin);
      }

      // If cardApplication is set, show the card menu item
      return true;
    }

    // If enableCard is not set, hide the card menu item
    return false;
  }

  onOpenedChange(opened: boolean): void {
    this.isNavOpened = opened;
    this.cdr.markForCheck();
  }

  onToggleSidebar(): void {
    this.isNavOpened = !this.isNavOpened;
    this.cdr.markForCheck();
  }

  onCloseNav(): void {
    if (this.isMobileView) {
      this.isNavOpened = false;
      this.cdr.markForCheck();
    }
  }

  onOpenEntitySwitcherClick(): void {
    if (this.isMobileView) {
      this.isNavOpened = false;
    }

    this.router.navigate(['change-client-service']);
  }

  onLogout(): void {
    this.logoutService.logout();
  }

  onOnboardingStart(): void {
    this.tourService.addTourToQueue('onboarding', true);
  }

  private handleToursOpening(): void {
    const layoutSettings = this.appSettingsService.getSettings<LayoutModuleSettings>('layout');
    const onboardingTourState = layoutSettings?.tourGuide?.onboarding;

    if (
      !onboardingTourState ||
      (!onboardingTourState.finish && onboardingTourState.lastSessionId !== this.logoutService.getAuthTime())
    ) {
      this.tourService.startTourIntro();
      this.dialogService.open(WelcomeModalComponent, { disableClose: true, closeOnNavigation: false, size: 'md' });
    }

    if (this.tourService.isTourIncomplete('notifications')) {
      this.tourService.addTourToQueue('notifications');
    }
  }

  private handleTourListeners(): void {
    const tourNames: TourName[] = ['onboarding', 'relaunch-onboarding', 'notifications'];
    this.tourService.stateChanges$
      .pipe(
        filter(({ name }) => tourNames.includes(name)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ state, name }) => this.tourService.updateTourSettings(state, name));
  }

  ngOnDestroy(): void {
    this.inboxStore.disconnectWs();
  }
}
