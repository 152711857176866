import { inject } from '@angular/core';

import { AppUserService } from '@1stdigital/ng-sdk/core';
import { CommonApiService } from '@app/core/services/common-api.service';
import { LayoutApiService } from '@app/layout/layout-api.service';
import { forkJoin, Observable, switchMap, tap } from 'rxjs';

import { ApplicationSettingsService, ClientService } from './services';
import { ClientsAccessorApiService } from './services/clients-accessor-api.service';
import { UserInfoStore } from './services/user-info.store';

export const assetsEntityAppsettingsResolver = (): Observable<unknown> => {
  const clientContextService = inject(ClientService);
  const layoutService = inject(LayoutApiService);
  const commonApiService = inject(CommonApiService);
  const clientsAccessorApiService = inject(ClientsAccessorApiService);
  const appUserService = inject(AppUserService);
  const userInfoStore = inject(UserInfoStore);
  const applicationSettingsService = inject(ApplicationSettingsService);

  const assetsAndEntity$ = layoutService.getClientAccessorList().pipe(
    tap((clients) => clientContextService.start(clients)),
    switchMap(() =>
      forkJoin([
        commonApiService.getAssets(),
        clientsAccessorApiService.getAssetMasterIds(),
        userInfoStore.getUserInfo(),
      ])
    ),
    tap(([assets, assetMasterIds]) => {
      appUserService.setAssets(assets);
      appUserService.setAppAvailableAssets(assetMasterIds);
    })
  );

  return assetsAndEntity$.pipe(switchMap(() => applicationSettingsService.init()));
};
