import { Injectable } from '@angular/core';

import { DropdownItem } from '@1stdigital/ng-sdk/core';
import { BehaviorSubject, finalize, map, Observable, tap } from 'rxjs';

import { DocumentTypeGroup } from '../models/document-type-group.type';
import { FileStorageApiService } from './file-storage-api.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypesStore {
  private readonly _documentTypes$ = new BehaviorSubject<Record<DocumentTypeGroup, DropdownItem[]> | null>(null);
  documentTypes$ = this._documentTypes$.asObservable();

  private readonly _isBusy$ = new BehaviorSubject(false);
  isBusy$ = this._isBusy$.asObservable();

  constructor(private fileStorageApiService: FileStorageApiService) {}

  getDocumentTypes(): Observable<Record<DocumentTypeGroup, DropdownItem[]>> {
    this._isBusy$.next(true);
    return this.fileStorageApiService.getDocumentTypes().pipe(
      tap((documentTypes) => this._documentTypes$.next(documentTypes)),
      finalize(() => this._isBusy$.next(false))
    );
  }

  getDocumentTypesByGroup(group: DocumentTypeGroup): Observable<DropdownItem[]> {
    return this._documentTypes$.pipe(map((groups) => (groups ? groups[group] : [])));
  }
}
