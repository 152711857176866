import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { ClientService, EnvironmentLoaderService } from '@app/core/services';
import { map } from 'rxjs';

export const readonlyPermissionGuard: CanActivateFn = () => {
  const clientService = inject(ClientService);
  const envService = inject(EnvironmentLoaderService);
  const router = inject(Router);

  return clientService.activeClient$.pipe(
    map((client) =>
      client?.isOnlineAccessReadOnly && !envService.environment['enableRolePolicy'] ? router.parseUrl('403') : true
    )
  );
};
