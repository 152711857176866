import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FdtDateTimePipe } from '@1stdigital/ng-sdk/shared';
import { InboxCardData, NotificationType } from '@app/core/models/interfaces/inbox.interface';

@Component({
  selector: 'app-inbox-card-update-text',
  imports: [FdtDateTimePipe],
  templateUrl: './inbox-card-update-text.component.html',
  styleUrl: './inbox-card-update-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxCardUpdateTextComponent {
  @Input({ required: true }) eventId!: NotificationType;
  @Input({ required: true }) requestData!: InboxCardData;
}
