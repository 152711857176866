import { UserPermissionService } from '../services/user-permission.service';

/**
 * Forms that fill the whole page should be blocked in the page-level with routes
 * List below are APIs of forms that are not user permission-checked through routes
 * e.g. mostly forms in modals
 * Please make sure the value and key is unique and that they sync with rego rules
 */
export const OPA_FORM_LIST = UserPermissionService.registerSectionPermission({
  Activity: {
    Cancel_Form: 'Activity-Cancel_Form',
    Approve_Form: 'Activity-Approve_Form',
    Attach_Files_Form: 'Activity-Attach_Files_Form',
    CSV_Download_Form: 'Activity-CSV_Download_Form',
    PDF_Download_Form: 'Activity-PDF_Download_Form',
  },
  Assetholdings: {
    Download_Activity_Statement_Form: 'Assetholdings-Download_Activity_Statement_Form',
    Download_Holding_Statement_Form: 'Assetholdings-Download_Holding_Statement_Form',
    Asset_Account_Form: 'Assetholdings-Asset_Account_Form',
    Alias_Form: 'Assetholdings-Alias_Form',
    Pledge_Form: 'Assetholdings-Pledge_Form',
  },
  Assettransfer: {
    Transferin_Crypto_Form: 'Assettransfer-Transferin_Crypto_Form',
    Transferin_Fiat_Form: 'Assettransfer-Transferin_Fiat_Form',
    Transferout_Crypto_Form: 'Assettransfer-Transferout_Crypto_Form',
    Transferout_Fiat_Form: 'Assettransfer-Transferout_Fiat_Form',
    Thirdparty_Crypto_Form: 'Assettransfer-Thirdparty_Crypto_Form',
    Thirdparty_Fiat_Form: 'Assettransfer-Thirdparty_Fiat_Form',
    Rsn_Form: 'Assettransfer-Rsn_Form',
  },
  Card: {
    Apply_Form: 'Card-Apply_Form',
    Activate_Form: 'Card-Activate_Form',
    Freeze_Form: 'Card-Freeze_Form',
    Unfreeze_Form: 'Card-Unfreeze_Form',
    Payment_Form: 'Card-Payment_Form',
    Setup_Pin_Form: 'Card-Setup_Pin_Form',
    Limits_Form: 'Card-Limits_Form',
    Notify_Form: 'Card-Notify_Form',
    Rewards_Form: 'Card-Rewards_Form',
  },
  Helpcenter: {
    Tickets_Form: 'Helpcenter-Tickets_Form',
    Tickets_Messages_Form: 'Helpcenter-Tickets_Messages_Form',
  },
  OTC: {
    Asset_Account_Form: 'OTC-Asset_Account_Form',
  },
  Whitelist: {
    Branch_Code_Form: 'Whitelist-Branch_Code_Form',
    Short_Code_Form: 'Whitelist-Short_Code_Form',
    Aba_Routing_Number_Form: 'Whitelist-Aba_Routing_Number_Form',
    Own_Form: 'Whitelist-Own_Form',
    Own_Delete_Form: 'Whitelist-Own_Delete_Form',
    Third_Party_Individual_Form: 'Whitelist-Third_Party_Individual_Form',
    Third_Party_Individual_Delete_Form: 'Whitelist-Third_Party_Individual_Delete_Form',
    Third_Party_Individual_Residential_Address_Form: 'Whitelist-Third_Party_Individual_Residential_Address_Form',
    Third_Party_Institution_Form: 'Whitelist-Third_Party_Institution_Form',
    Third_Party_Institution_Delete_Form: 'Whitelist-Third_Party_Institution_Delete_Form',
    Third_Party_Institution_Registered_Address_Form: 'Whitelist-Third_Party_Institution_Registered_Address_Form',
    Third_Party_Relationship_Form: 'Whitelist-Third_Party_Relationship_Form',
    Third_Party_Holder_Name_Form: 'Whitelist-Third_Party_Holder_Name_Form',
    Crypto_Wallet_Form: 'Whitelist-Crypto_Wallet_Form',
    Crypto_Wallet_Delete_Form: 'Whitelist-Crypto_Wallet_Delete_Form',
  },
});
