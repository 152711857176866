export enum ActivityType {
  TransferIn = 'transfer-in',
  TransferOut = 'transfer-out',
  RsnTransfer = 'rsn-transfer',
  ThirdPartyTransfer = 'third-party-transfer',
  PledgeTransfer = 'pledge-transfer',
  Otc = 'otc',
  Fx = 'fx',
  Bond = 'bond',
  Card = 'card',
  None = 'none',
}

export const activityTypeDropdownItems = [
  {
    label: 'Transfer-In',
    value: ActivityType.TransferIn,
  },
  {
    label: 'Transfer-Out',
    value: ActivityType.TransferOut,
  },
  {
    label: 'RSN Transfer',
    value: ActivityType.RsnTransfer,
  },
  {
    label: 'Third Party Transfer',
    value: ActivityType.ThirdPartyTransfer,
  },
  {
    label: 'OTC',
    value: ActivityType.Otc,
  },
  {
    label: 'Card Payment',
    value: ActivityType.Card,
  },
  // hidden until implemented
  // {
  //   label: 'Fx',
  //   value: ActivityType.Fx,
  // },
  // {
  //   label: 'Bonds',
  //   value: ActivityType.Bond,
  // },
];
