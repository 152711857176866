@switch (eventId) {
  @case ('ams.whitelisting.created') {
    Whitelist request has been submitted, First Digital Trust will review the submission within 24 hours
  }
  @case ('ams.whitelisting.approved') {
    Wallet address {{ requestData.address }} has been successfully whitelisted
  }
  @case ('ams.whitelisting.rejected') {
    Wallet address {{ requestData.address }} whitelist request has been rejected
  }
}
