import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { IconComponent } from '@1stdigital/ng-sdk/icon';

@Component({
  selector: 'app-info-block',
  imports: [IconComponent],
  templateUrl: './info-block.component.html',
  styleUrl: './info-block.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBlockComponent {
  @Input() showIcon = true;
  @Input() color: 'primary' | 'tertiary' = 'primary';
  @Input() iconPosition?: 'top' | 'center';
}
