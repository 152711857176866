import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { readonlyPermissionGuard } from '@app/core/guards/readonly-permission.guard';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';

import { assetsEntityAppsettingsResolver } from './core/assets-entity-appsettings.resolver';
import { cardGuard } from './core/guards/card-guard';
import { kycSelfieGuard } from './core/guards/kyc-selfie-guard';
import { RouterHelperService } from './core/services/router-helper.service';
import { KycApiService } from './kyc-selfie/kyc-api.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    resolve: {
      assetsEntityAppsettings: assetsEntityAppsettingsResolver,
    },
    children: [
      {
        path: '',
        canActivate: [kycSelfieGuard],
        loadComponent: () => import('./layout/layout/layout.component').then((m) => m.LayoutComponent),
        children: RouterHelperService.protectedRoutes([
          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
          {
            path: 'noop-component',
            loadComponent: () => import('./layout/noop-component/noop.component').then((m) => m.NoopComponent),
          },
          {
            path: 'activity',
            loadChildren: () => import('./activity/activity.module').then((m) => m.ActivityModule),
          },
          {
            path: 'asset-holdings',
            loadChildren: () => import('./asset-holding/asset-holding.module').then((m) => m.AssetHoldingModule),
          },
          {
            path: 'asset-transfer',
            canActivate: [readonlyPermissionGuard],
            loadChildren: () => import('./asset-transfer/asset-transfer.module').then((m) => m.AssetTransferModule),
          },
          {
            path: 'dashboard',
            loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
          },
          {
            path: 'otc',
            canActivate: [readonlyPermissionGuard],
            loadChildren: () => import('./otc/otc.module').then((m) => m.OtcModule),
          },
          {
            path: 'treasury-bills',
            loadChildren: () => import('./treasury-bill/treasury-bill.module').then((m) => m.TreasuryBillModule),
          },
          {
            path: 'dev',
            loadComponent: () => import('./dev/dev/dev.component').then((m) => m.DevComponent),
          },
          {
            path: 'change-client-service',
            title: 'Change Client & Service Suite',
            loadComponent: () =>
              import('./layout/change-client-service/change-client-service.component').then(
                (m) => m.ChangeClientServiceComponent
              ),
          },
          {
            path: 'whitelist',
            canActivate: [readonlyPermissionGuard],
            loadChildren: () => import('./whitelist/whitelist.module').then((m) => m.WhitelistModule),
          },
          {
            path: 'client',
            loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
          },
          {
            path: 'card',
            canActivate: [cardGuard],
            loadChildren: () => import('./card/card.module').then((m) => m.CardModule),
          },
          {
            path: 'help-center',
            loadChildren: () => import('./help-center/help-center.module').then((m) => m.HelpCenterModule),
          },
        ]),
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: '404',
        loadComponent: () =>
          import('./layout/page-not-found/page-not-found.component').then((m) => m.PageNotFoundComponent),
      },
      {
        path: '403',
        loadComponent: () =>
          import('./layout/page-no-permission/page-no-permission.component').then((m) => m.PageNoPermissionComponent),
      },
    ],
  },
  {
    path: 'kyc-selfie',
    canActivate: [MsalGuard],
    loadChildren: () => import('src/app/kyc-selfie/kyc-selfie.module').then((m) => m.KycSelfieModule),
  },
  {
    path: 'inactive-client',
    canActivate: [MsalGuard],
    loadComponent: () => import('./inactive-client/inactive-client.component').then((m) => m.InactiveClientComponent),
  },
  { path: 'redirecting', component: MsalRedirectComponent },
  {
    path: 'redirect-error',
    loadComponent: () => import('./redirect-error/redirect-error.component').then((m) => m.RedirectErrorComponent),
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [KycApiService],
})
export class AppRoutingModule {}
