import { inject, Injectable } from '@angular/core';

import { ClientService } from '@app/core/services/client.service';
import { MsalService } from '@azure/msal-angular';
import { TokenClaims } from '@azure/msal-common';
import { LoadedPolicy, loadPolicy } from '@open-policy-agent/opa-wasm';
import { BehaviorSubject, Observable } from 'rxjs';

import { EnvironmentLoaderService } from './environment-loader.service';

// This is a placeholder for all the permission names;
// We are using this to register all the permission names in one place so we can simplify the logic
// and determinate if permission is routerLink or section;
const sectionPermissions: string[] = [];

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  private readonly clientService = inject(ClientService);
  private readonly cache = new Map<string, boolean>();
  private readonly ready = new BehaviorSubject(false);
  ready$: Observable<boolean>;
  policy!: LoadedPolicy;

  roleData!: object | ArrayBuffer;

  /**
   * @description
   * Extract the permissionNames out of the object and register them
   * @param obj Object in any shape that holds the permission names;
   * @returns Same object;
   */
  static registerSectionPermission<T extends object>(obj: T): T {
    const permissionNames = extractPermissionNames(obj);
    permissionNames.forEach((permissionName) => {
      sectionPermissions.push(permissionName);
    });

    return obj;
  }

  constructor(
    private readonly authService: MsalService,
    private readonly envService: EnvironmentLoaderService
  ) {
    this.ready$ = this.ready.asObservable();
  }

  /**
   * Initialize the User permission service (OPA policy);
   * We need to ensure this is done before we can check
   * if user has permission to access certain route;
   * @returns
   */
  init(): Promise<Response | void> | null {
    if (!this.isPermissionCheckEnabled()) {
      this.ready.next(true);
      return null;
    }

    if (!this.policy) {
      // tag is used to force the browser to always fetch the file from the server, disabling cache this file in the browser.
      const tag = new Date().getTime();

      return fetch(`${this.envService.environment['opaPolicyWasmS3BucketUrl']}?v=${tag}`).then((response) => {
        return response.arrayBuffer().then((bytes) => {
          return loadPolicy(bytes).then((policy: LoadedPolicy) => {
            this.getRoleData(policy);
          });
        });
      });
    }

    return null;
  }

  shouldShowInstructions(): boolean {
    return this.hasPermission('/asset-transfer') || this.hasPermission('/otc');
  }

  /**
   * Helper method to check if permission check is enabled
   * @returns true if permission check is enabled
   */
  private isPermissionCheckEnabled(): boolean {
    return this.envService.environment['enableRolePolicy'] === true;
  }

  hasPermission(sectionNameOrUrl: string = '', type: PermissionType = PermissionType.Read): boolean {
    if (this.isPermissionCheckEnabled() && sectionNameOrUrl) {
      let requiredPermissionObj: OpaRequestProps = {};

      if (sectionPermissions.includes(sectionNameOrUrl)) {
        requiredPermissionObj = {
          sectionName: sectionNameOrUrl,
        };
      } else {
        requiredPermissionObj = {
          routerlink: sectionNameOrUrl,
        };
      }

      return this.hasPermissionInternal(requiredPermissionObj, type);
    }

    // TODO: delete below after OPA permissions are setup
    const activeClient = this.clientService.activeClient$.value;

    if (!activeClient) {
      return true;
    }

    return !activeClient.isOnlineAccessReadOnly;
  }

  /**
   * Method to get the role mapping data json
   * @param policy
   * @returns
   */
  getRoleData(policy: LoadedPolicy): void {
    const tag = new Date().getTime();

    fetch(`${this.envService.environment['opaPolicyJsonS3BucketUrl']}?v=${tag}`).then((data) => {
      this.roleData = data;
      this.policy = policy;
      this.ready.next(true);
    });
  }

  /**
   * Method to check if user has permission to access certain route;
   * We are using OPA policy to check if user has permission to access certain route;
   * We are caching the result of the policy check, so we don't have to call OPA policy every time;
   * @param requiredPermissionObj In our case this is an object with routerLink and sectionName attributes
   * @param type Currently just a placeholder, not sure if we will need this in future;
   * @returns
   */
  private hasPermissionInternal(requiredPermissionObj: OpaRequestProps, type: PermissionType): boolean {
    const inputParams = this.getInputParameters(requiredPermissionObj);
    const cacheKey = `${JSON.stringify(requiredPermissionObj)}_${JSON.stringify(this.clientService.activeClient$.value)}_{_${type}`;

    // resolve logout redirect to forbidden page issue
    // need to go to / index page first instead of forbidden page
    if (!inputParams) {
      return true;
    }

    if (this.cache.has(cacheKey)) {
      return this.cache.get(cacheKey) || false;
    }

    if (this.policy) {
      this.policy.setData(this.roleData);
      const policyEvaluate = this.policy.evaluate(inputParams);
      this.cache.set(cacheKey, policyEvaluate[0].result);
      return policyEvaluate[0].result;
    }

    return false;
  }

  /**
   * Returns input parameters for OPA policy
   * it can extend in the future, since I am not sure we can cover everything with this;
   * @param appUrl string
   * @param sectionName string
   * @returns
   */
  private getInputParameters(requiredPermissionObj: OpaRequestProps): {
    request: OpaRequestProps;
    userAuthData: { userId: string; roles: string[]; hasOnlineAccess: boolean; hasReadOnlyAccess: boolean };
  } | null {
    const idTokenClaims: TokenClaims & {
      [key: string]: string | number | string[] | object | undefined | unknown;
    } = this.authService.instance.getActiveAccount()!.idTokenClaims!;

    // resolve the logout redirect issue
    // stop showing unauthorized error toast right before asking the user login again
    if (!idTokenClaims) {
      return null;
    }

    return {
      request: {
        ...requiredPermissionObj,
      },
      userAuthData: {
        userId: idTokenClaims['sub'] ?? '',
        roles: this.clientService.activeClient$.value?.roles || [],
        hasOnlineAccess: true,
        hasReadOnlyAccess: this.clientService.activeClient$.value?.isOnlineAccessReadOnly || false,
      },
    };
  }
}

/**
 * Permission types
 * This is an draft, not sure if we will need this in future;
 */
enum PermissionType {
  Read = 'read',
  Write = 'write',
  Delete = 'delete',
  Update = 'update',
}

interface OpaRequestProps {
  routerlink?: string;
  sectionName?: string;
}

/**
 * @description
 * Helper recursive function that extract all the string values from the object;
 * We need this to register all the permission names in one place so we can simplify the logic here;
 * @param obj Any object that holds permisison names;
 * @returns Array of permission names
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function extractPermissionNames(obj: any): string[] {
  let result: string[] = [];

  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      result.push(obj[key]);
    } else if (typeof obj[key] === 'object') {
      // Recursively call the function for nested objects
      result = result.concat(extractPermissionNames(obj[key]));
    }
  }

  return result;
}
