import { AfterContentInit, ContentChild, DestroyRef, Directive, ElementRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';

import { MatTooltip } from '@angular/material/tooltip';

import { ClientService, EnvironmentLoaderService } from '@app/core/services';
import { UserPermissionService } from '@app/core/services/user-permission.service';

@Directive({
  selector: '[appProtectIfNoPermission]',
  standalone: true,
  hostDirectives: [MatTooltip],
})
export class ProtectIfNoPermissionDirective implements AfterContentInit {
  @ContentChild(RouterLink, { static: true })
  link: RouterLink | undefined = undefined;
  /**
   * Determinate strategy we will use in order to protect the content;
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('npStrategy') strategy: 'disable' | 'hide' = 'disable';
  // type = value of OPA_FORM_LIST
  @Input() permissionSectionName: string = '';

  // This class can be used for styling purposes outside of this directive;
  private readonly DISABLED_CLASS_NAME = 'appProtectIfNoPermission';
  private readonly displayPropertyValue = this.elementRef.nativeElement.style.display;

  constructor(
    private readonly environmentLoaderService: EnvironmentLoaderService,
    private readonly userPermissionService: UserPermissionService,
    private readonly clientService: ClientService,
    private readonly destroyRef: DestroyRef,
    private readonly elementRef: ElementRef,
    private readonly matTooltip: MatTooltip
  ) {}

  ngAfterContentInit(): void {
    this.checkForUserPermission();

    this.clientService.activeClient$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.checkForUserPermission();
    });
  }

  private checkForUserPermission(): void {
    if (!this.environmentLoaderService.environment['enableRolePolicy'] && this.strategy === 'disable') {
      return;
    }

    let hasPermission: boolean = false;

    if (this.link) {
      hasPermission = this.userPermissionService.hasPermission(this.link.href as string);
    } else if (this.permissionSectionName) {
      hasPermission = this.userPermissionService.hasPermission(this.permissionSectionName);
    } else {
      // TODO: delete below after OPA permissions are setup
      hasPermission = this.userPermissionService.hasPermission();
    }

    if (!hasPermission) {
      this.userDoesNotHavePermission();
    } else {
      this.elementRef.nativeElement.style.display = this.displayPropertyValue;
      this.matTooltip.message = '';
      this.elementRef.nativeElement.classList.remove(this.DISABLED_CLASS_NAME);
    }
  }

  private userDoesNotHavePermission(): void {
    if (this.strategy === 'disable') {
      this.disable();
    } else if (this.strategy === 'hide') {
      this.hide();
    }
  }

  /**
   * Currently supports only link;
   */
  private disable(): void {
    this.matTooltip.message = `You don't have permission to access this page`;
    this.elementRef.nativeElement.classList.add(this.DISABLED_CLASS_NAME);
  }

  private hide(): void {
    this.elementRef.nativeElement.style.display = 'none';
  }
}
