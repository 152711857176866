@switch (eventId) {
  @case ('ams.instruction.fiat.deposit.pending.client') {
    Deposit instruction for {{ requestData.currency }} {{ requestData.amount }} is in progress (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.deposit.cancelled.client') {
    Deposit instruction for {{ requestData.currency }} {{ requestData.amount }} has been cancelled (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.deposit.initiated.client') {
    Deposit instruction created for {{ requestData.currency }} {{ requestData.amount }} to (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.deposit.completed.client') {
    Deposit instruction for {{ requestData.currency }} {{ requestData.amount }} is complete (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.payment.pending.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} is in progress (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.payment.completed.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} is complete (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.payment.cancelled.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} has been cancelled (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.payment.initiated.client') {
    Payment instruction created for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.withdrawal.cancelled.client') {
    Withdrawal instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} has been cancelled (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.payment.rejected.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} has been rejected (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.withdrawal.initiated.client') {
    Withdrawal instruction created for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }}.
  }
  @case ('ams.instruction.fiat.withdrawal.completed.client') {
    Withdrawal instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} is complete (Ref:
    {{ requestData.referenceNumber }}).
  }
}
