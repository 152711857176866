@switch (eventId) {
  @case ('ams.instruction.crypto.deposit.initiated.client') {
    Deposit instruction created for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.packageName }} (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.payment.initiated.client') {
    Payment instruction created for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }}.
  }
  @case ('ams.instruction.crypto.payment.completed.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }} is complete
    (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.payment.cancelled.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }} has been
    cancelled (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.withdrawal.cancelled.client') {
    Withdrawal instruction for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }} has been
    cancelled (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.withdrawal.completed.client') {
    Withdrawal instruction for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }} is
    complete (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.withdrawal.initiated.client') {
    Withdrawal instruction created for
    {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }}.
  }
  @case ('ams.instruction.crypto.deposit.completed.client') {
    Deposit instruction for {{ requestData.currency }} {{ requestData.amount }} is complete (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.deposit.cancelled.client') {
    Deposit instruction for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.packageName }} has
    been cancelled (Ref: {{ requestData.referenceNumber }}).
  }
}
